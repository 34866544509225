import React, { useContext } from 'react'
import { ImCart } from "react-icons/im"
import { WishlistContext } from "../../contexts/WishlistContext"


const MoveFromWishlistToCart = ({ product_id }) => {
    const { moveWishlistItemToCart } = useContext(WishlistContext);

    return (


        <div className="addToCartButton "
        
            title="Move to Cart"
            onClick={() => {
                moveWishlistItemToCart(product_id);
                // addCartItem(item.id);
            }
            }
        >
            <ImCart
                className="icon"
            />
        </div>





    )
}
export default MoveFromWishlistToCart;