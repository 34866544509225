import * as Yup from 'yup';


// export const signUpValidation = Yup.object().shape({
//   first_name: Yup.string().required("Please enter your first name"),
//   last_name: Yup.string().required("Please enter your last name"),
//   email: Yup.string()
//     .required("Please enter your email address")
//     .email("Please enter a valid email address")

//     .test('Unique Email', 'Email already in use',
//       function (value) {
//         return new Promise((resolve, reject) => {

//           if (!value || !isValidEmail(value))
//             return true;

//           axios.post(`${process.env.REACT_APP_BASE_URL}api/buyers/check-email-exist`, { 'email': value })
//             .then(res => {
//               if (res.data.emailExist) { resolve(false) }
//               resolve(true)
//             })
//           // .error(error => {
//           //   console.log('error', error);
//           //   resolve(true)
//           // }
//           // )
//         })
//       }
//     ),




//   mobile: Yup.number().required("Please enter your mobile number"),
//   country: Yup.string().required("Please select your country"),
//   gender: Yup.string().required("Please select your gender"),
//   password: Yup
//     .string()
//     .required('Please choose your password')
//     .matches(
//       /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/,
//       "Use 8-16 characters with one upper letters and at least one number"
//     ),
//   confirm_password: Yup
//     .string()
//     .required('Please retype your password')
//     .when("password", {
//       is: val => (val && val.length > 0 ? true : false),
//       then: Yup.string().oneOf(
//         [Yup.ref("password")],
//         "Your password and confirmation password do not match"
//       )
//     }),
// })


export const firstStepSignUpValidation = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
})

export const secondStepSignUpValidation = Yup.object().shape({
  first_name: Yup.string().required("Please enter your first name"),
  last_name: Yup.string().required("Please enter your last name"),
  password: Yup
    .string()
    .required('Please choose your password')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/,
      "Use 8-16 characters with one upper letters and at least one number"
    ),
  confirm_password: Yup
    .string()
    .required('Please retype your password')
    .when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Your password and confirmation password do not match"
      )
    })
})

export const thirdStepSignUpValidation = Yup.object().shape({
  mobile: Yup.number().required("Please enter your mobile number"),
  country_id: Yup.number().required("Please select your country"),
  gender: Yup.string().required("Please select your gender")
})


export const guestSignUpValidation = Yup.object().shape({
  first_name: Yup.string().required("Please enter your first name"),
  last_name: Yup.string().required("Please enter your last name"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
  mobile: Yup.number().required("Please enter your mobile number"),
  //country: Yup.number().required("Please select your country"),
  gender: Yup.string().required("Please select your gender"),
})


export const qaValidation = Yup.object().shape({
  questioner_name: Yup.string()
    .required("Please enter your name"),
  questioner_email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
  question: Yup.string()
    .min(50, "Question must have at least 50 characters")
    .max(200, "Question can't be longer than 200 characters")
    .required("Please enter your question"),
});


// export const itemCustomizationValidation = Yup.object().shape({
//   engraveName: Yup.string().required("Please enter a name to engrave"),
//   checkedOptionThree: Yup.boolean(),
//   message: Yup.string().when("checkedOptionThree", {
//     is: true,
//     then: Yup
//       .string()
//       .required("Required field"),
//     otherwise: Yup.string()
//   })
// });

export const itemCustomizationValidation = Yup.object().shape({
  selectedItems: Yup.object({
    toWhom: Yup.boolean(),
    from: Yup.boolean(),
    note: Yup.boolean(),
    logo: Yup.boolean(),
  }),
  toWhom: Yup.string().when("selectedItems.toWhom", {
    is: true,
    then: Yup
      .string()
      .required("Pelase enter a receiver name."),
    otherwise: Yup.string()
  }),
  from: Yup.string().when("selectedItems.from", {
    is: true,
    then: Yup
      .string()
      .required("Pelase enter a sender name."),
    otherwise: Yup.string()
  }),
  note: Yup.string().when("selectedItems.note", {
    is: true,
    then: Yup
      .string()
      .max(300, "Note can't be longer than 300 characters")
      .required("Pelase enter a special note."),
    otherwise: Yup.string()
  }),
  brief: Yup.string().max(300, "Brief can't be longer than 300 characters")
});


export const itemPackingValidation = Yup.object().shape({
  packaging_id: Yup.string().required('Please select an option'),
});

export const orderReviewValidation = Yup.object().shape({
  rating: Yup.number().required('Please rate this product'),
  comments: Yup.string().required("Please enter your comments")
});

export const orderFeedbackValidation = Yup.object().shape({
  comments: Yup.string().required("Please enter your comments"),
  // file: Yup.mixed().required('File is required'),
});

export const forgotPasswordValidation = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
});

export const resetPasswordValidation = Yup.object().shape({
  password: Yup
    .string()
    .required('Please choose your password')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/,
      "Use 8-16 characters with one upper letters and at least one number"
    ),
  confirm_password: Yup
    .string()
    .required('Please retype your password')
    .when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Your password and confirmation password do not match"
      )
    }),
});


export const contactFormValidation = Yup.object().shape({
  full_name: Yup.string()
    .required("Please enter your name"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
  message: Yup.string()
    .max(200, "Message can't be longer than 300 characters")
    .required("Please enter your inquiry"),
});


export const loginFormValidation = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email address"),
  password: Yup
    .string()
    .required('Please enter your password')
});

export const checkboxGroupValidation = Yup.object({
  checked: Yup.array().min(1, 'Select atleast one option')
});


export const shippingAddressValidation = Yup.object().shape({
  first_name: Yup.string().required("Please enter receiver's first name"),
  last_name: Yup.string().required("Please enter receiver's first name"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter receiver's email address"),
  // mobile: Yup.string()
  // .required("Please enter receiver's mobile")
  // .matches(
  //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  //     , 'Mobile number is not valid'),
  mobile: Yup.number().required("Please enter receiver's mobile number"),
  country_id: Yup.number().required("Please select receiver's country"),
  city: Yup.string().required("Please enter receiver's city"),
  address: Yup.string().required("Please enter receiver's full address"),
});

export const sendMessageValidation = Yup.object().shape({
  subject: Yup.string().required("Please enter subject"),
  message: Yup.string().required("Please enter message"),
});