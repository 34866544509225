import React, { useEffect, useContext, useReducer, useState } from 'react'
import "./Products.scss"
import { FullPageLoading } from "../../components/Loading/Loading"
import { Container, Col, Row, Badge, Button, Tabs, Tab, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import InnerPageHeader from '../../components/InnerPageHeader/InnerPageHeader'
import { isTrue, isItemInCart } from "../../helpers/DOMHelpers"
import { FaShareAlt, FaHandPointLeft, FaHandPointDown } from "react-icons/fa"
import AddToWishlist from "../../components/AddToWishlist/AddToWishlist"
import ProductGallery from '../../components/ProductGallery/ProductGallery'
import QA from '../../components/QA/QA'
import FrequentlyBoughtTogether from '../../components/FrequentlyBoughtTogether/FrequentlyBoughtTogether'
import { ErrorComponent } from "../../components/Alert/Alert"
import OutOfStock from '../../components/OutOfStock/OutOfStock'
import ProductRating from '../../components/ProductRating/ProductRating'
import AddToCart from './../../components/Cart/AddToCart/AddToCart';
import AddedToCart from '../../components/Cart/AddedToCart/AddedToCart'
import { GlobalContext } from './../../contexts/GlobalContext';
import { CartContext } from "../../contexts/CartContext"
import { ProductDeatilsReducer } from "../../reducers/ProductDeatilsReducer"
import { fetchProductDetailsAPI } from '../../apis/productAPIs'
import { useHistory } from 'react-router-dom'
import { CloseButton } from '../../components/CloseOverlayButton/CloseOverlay';
import { getPriceClasses, getSelectedCurrencySymbol, getPriceInSelectedCurrency } from "../../helpers/MoneyHelper"
import Classification from '../../components/Classification/Classification';
import { InlineShareButtons, InlineReactionButtons } from 'sharethis-reactjs';

const ProductDetails = (props) => {
    const { defaultSelectedCurrency } = useContext(GlobalContext).globalData;
    const { currencies } = useContext(GlobalContext).globalData.data;
    const { match } = props;
    const slug = match.params.slug;
    const history = useHistory()

    const { getFrontendMessage } = useContext(GlobalContext);
    const [productState, productStateDispatch] = useReducer(ProductDeatilsReducer, {
        product: {},
        isFetching: true,
        hasError: false
    })

    const [shareModal, setShareModal] = useState(false);


    const showShareModal = () => {
        setShareModal(true);
    };

    const closeShareModal = () => {
        setShareModal(false);
    }

    const productSpecfications = () => {

        let productSpecs = [];
        let subCatSpecs = [];
        if (
            productState.product.specifications
            && (
                (productState.product.specifications.product && productState.product.specifications.product.length > 0)
                || (productState.product.specifications.sub_category && productState.product.specifications.sub_category.length > 0)
            )
        ) {

            if (productState.product.specifications.product && productState.product.specifications.product.length > 0) {
                productSpecs = productState.product.specifications.product
            }

            if (productState.product.specifications.sub_category && productState.product.specifications.sub_category.length > 0) {
                subCatSpecs = productState.product.specifications.sub_category
            }


        }
        const allSpecs = productSpecs.concat(subCatSpecs);
        return allSpecs;
    }

    const loadProductDetails = async () => {
        productStateDispatch({
            type: "FETCH_PRODUCT_DETAILS_REQUEST"
        })
        const apiResult = await fetchProductDetailsAPI(slug);
        if (apiResult.status && apiResult.data && apiResult.data.id) {
            productStateDispatch({
                type: "FETCH_PRODUCT_DETAILS_SUCCESS",
                payload: apiResult.data
            })
        } else {
            history.push('/pagenotfound')
        }
    }

    useEffect(() => {
        loadProductDetails();
    }, [slug]);
    const { cart } = useContext(CartContext);
    // Frontend Messages - Product Details //

    return (
        <>
            {(productState.product && productState.product.id) &&
                <InnerPageHeader lastItemTitle={productState.product.title} />
            }

            <Container className="p-0">
                <Col className="itemDetailsPage innerpageCard prLess hasMarginTop hasShadow">
                    <Row>
                        <Col>
                            {productState.isFetching ? (
                                <FullPageLoading />
                            ) : (
                                <>
                                    {productState.hasError ? (
                                        <ErrorComponent />
                                    ) : (
                                        <>

                                            {(productState.product && productState.product.id) ? (
                                                <>
                                                    <Row className="columnReverseMobile">
                                                        <Col className="itemDetailsLeftContainer" xl={5} lg={6} sm={6} md={6} xs={12}>
                                                            <ul>
                                                                <li className="itemCategory">
                                                                    {productState.product.type.name}
                                                                </li>
                                                                <li
                                                                    className={`itemTitle ${productState.product.title.length >= 20 ? "mediumText" :
                                                                        [
                                                                            productState.product.title.length >= 30 ? "smallText" : ""
                                                                        ]}`}
                                                                >
                                                                    {productState.product.title}
                                                                </li>
                                                                <li className="itemFlags">
                                                                    <ul>
                                                                        {!productState.product.in_stock &&
                                                                            <li>

                                                                                <Badge pill variant="tertiaryGray" className="">
                                                                                    Out of Stock
                                                                                </Badge>


                                                                            </li>
                                                                        }
                                                                        {isTrue(productState.product.is_customizable) &&
                                                                            <li>
                                                                                <OverlayTrigger
                                                                                    key="top"
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip>
                                                                                            {getFrontendMessage('Product', 'Tags Tooltip', 'Customization Tooltip', 'Text')}
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <Badge pill variant="secondaryPink">
                                                                                        Customizable
                                                                                    </Badge>
                                                                                </OverlayTrigger>

                                                                            </li>
                                                                        }



                                                                        {isTrue(productState.product.is_sp_packaging) &&
                                                                            <li>
                                                                                <OverlayTrigger
                                                                                    key="top"
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip>
                                                                                            {getFrontendMessage('Product', 'Tags Tooltip', 'Packaging Tooltip', 'Text')}
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <Badge pill className="tertiaryGray outlined">
                                                                                        Packaging
                                                                                    </Badge>
                                                                                </OverlayTrigger>
                                                                            </li>
                                                                        }
                                                                        {productState.product.shipping && isTrue(productState.product.shipping.free_shipping) &&
                                                                            <li>
                                                                                ~
                                                                            </li>
                                                                        }
                                                                        {isTrue(productState.product.in_stock) &&
                                                                            <li>
                                                                                <OverlayTrigger
                                                                                    key="top"
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip>
                                                                                            {getFrontendMessage('Product', 'Tags Tooltip', 'InStock Tooltip', 'Text')}
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <Badge pill className="tertiaryGray outlined">
                                                                                        In Stock
                                                                                    </Badge>
                                                                                </OverlayTrigger>
                                                                            </li>
                                                                        }
                                                                    </ul>

                                                                </li>
                                                                <li>
                                                                    <Row className="align-items-center itemDetailsActionButtons">
                                                                        <Col xl={7} lg={8} md={8} sm={12} xs={12}>
                                                                            <Row noGutters>
                                                                                <div className="productRating md mb-2">
                                                                                    <ProductRating
                                                                                        initialRating={productState.product.reviews.rating}
                                                                                        readonly={true}
                                                                                    />
                                                                                </div>
                                                                                <div className="itemReviewsContainer">
                                                                                    <span className="itemReviews">{productState.product.reviews.count} Review(s) &nbsp;/&nbsp;  {productState.product.reviews.rating} Rating </span>
                                                                                </div>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col lg={2} xs={2} className="textCenter">
                                                                            <div className="addToWishlist">
                                                                                <AddToWishlist
                                                                                    product_id={productState.product.id}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={3} xs={10}>
                                                                            <Button variant="whiteButton" className="uppercase hasShadow" size="md"
                                                                                title="Share on Social Media" onClick={() => showShareModal()}
                                                                            >
                                                                                Share
                                                                                <FaShareAlt
                                                                                    style={{
                                                                                        fontSize: 16,
                                                                                        marginLeft: 5,
                                                                                        marginBottom: 3,
                                                                                    }}
                                                                                />
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                {productState.product.last_piece_message && <li className="oneleftText">
                                                                    {productState.product.last_piece_message}

                                                                </li>}

                                                                <li>
                                                                    <div className="itemPrice isDiscountedprice" lg={7}>


                                                                        <div className="price" >
                                                                            {getSelectedCurrencySymbol(defaultSelectedCurrency, currencies) + getPriceInSelectedCurrency(productState.product.price, defaultSelectedCurrency, currencies, true)}
                                                                            {/* ${productState.product.price} */}
                                                                        </div>
                                                                        {(productState.product.old_price && productState.product.old_price > productState.product.price) && (
                                                                            <div className="oldPrice"><del>{getSelectedCurrencySymbol(defaultSelectedCurrency, currencies) + getPriceInSelectedCurrency(productState.product.old_price, defaultSelectedCurrency, currencies, true)}</del></div>
                                                                        )}
                                                                    </div>
                                                                </li>
                                                            </ul>

                                                            <Classification productID={productState.product.id} />

                                                            <div className="itemDetailsTabs customTab mt-4">
                                                                <Tabs defaultActiveKey="overview" transition={false} variant="pills">
                                                                    <Tab eventKey="overview" title="Overview" tabClassName="">
                                                                        <div className="tabContent">
                                                                            {(productState.product.short_description || productState.product.hint || productState.product.description)
                                                                                ?
                                                                                <ul>

                                                                                    {(productState.product.short_description) &&
                                                                                        <li className="mb-4">
                                                                                            <strong>{productState.product.short_description}</strong>
                                                                                        </li>
                                                                                    }

                                                                                    {(productState.product.hint) &&
                                                                                        <li>
                                                                                            <div className="heading">
                                                                                                <strong>Hint:</strong>
                                                                                            </div>
                                                                                            <div className="content">
                                                                                                {productState.product.hint}
                                                                                            </div>
                                                                                        </li>
                                                                                    }

                                                                                    {(productState.product.description) &&
                                                                                        <li>
                                                                                            <div className="heading">
                                                                                                <strong>Details:</strong>
                                                                                            </div>
                                                                                            <div className="content">
                                                                                                {console.log(productState.product.description)}
                                                                                                <div className="htmlContent" dangerouslySetInnerHTML={{ __html: productState.product.description }} />

                                                                                            </div>
                                                                                        </li>
                                                                                    }
                                                                                </ul>
                                                                                :
                                                                                null
                                                                            }

                                                                        </div>
                                                                    </Tab>
                                                                    {
                                                                        (productSpecfications().length > 0) &&

                                                                        <Tab eventKey="specification" title="Specification" tabClassName="">
                                                                            {
                                                                                <ul>
                                                                                    {
                                                                                        productSpecfications().map((spec, index) => (
                                                                                            <li key={index}>
                                                                                                <div className="content">
                                                                                                    <div ><strong>{spec.name}: </strong>{spec.value}</div>
                                                                                                </div>

                                                                                            </li>
                                                                                        ))
                                                                                    }
                                                                                </ul>
                                                                            }

                                                                        </Tab>
                                                                    }
                                                                </Tabs>
                                                            </div>


                                                            {isTrue(!productState.product.in_stock) ? (
                                                                <>
                                                                    <OutOfStock itemID={productState.product.id} />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="itemDetailsBottom">
                                                                        <div className="itemDetailsBottomContent m-0">
                                                                            <div className="addToCart cartButtonStyle textCenter" lg={6}>
                                                                                {
                                                                                    isItemInCart(productState.product.id, cart.cartItems.items)
                                                                                        ? <AddedToCart
                                                                                            isCartButtonStyle={true}
                                                                                        />
                                                                                        : <AddToCart
                                                                                            item={productState.product}
                                                                                            isCartButtonStyle={true}
                                                                                        />
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Col>

                                                        <Col xl={7} lg={6} sm={6} md={6} xs={12} className="productDetailsRightContainer">
                                                            <ProductGallery
                                                                supplier={productState.product.supplier}
                                                                productid={productState.product.id}
                                                                itemImage={productState.product.image}
                                                                {...props}
                                                            />



                                                        </Col>


                                                    </Row>
                                                    <Row noGutters className="columnReverseMobileTablet hasSmallDeviceMarginTop">
                                                        <Col className="itemDetailsLeftContainer" lg={5}>
                                                            <QA
                                                                productid={productState.product.id}
                                                                itemReviews={productState.product.reviews}
                                                                {...props}
                                                            />
                                                        </Col>

                                                        <Col lg={7} className="productDetailsRightContainer">
                                                            <Col xl={11} lg={11} sm={12} md={12} xs={12} className="p-0">
                                                                <div className="haveAQuestionContainer">
                                                                    <div className="haveQuestion w-100">
                                                                        <div className=" d-flex align-items-center">
                                                                            <span className="icon showOnMobileTablet">
                                                                                <FaHandPointDown />
                                                                            </span>
                                                                            <span className="icon hideOnMobileTablet">
                                                                                <FaHandPointLeft />
                                                                            </span>
                                                                            {getFrontendMessage('Product', 'Question for Product', 'Message', 'Text')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : (
                                                <ErrorComponent />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Container>
            {
                (productState.product && productState.product.id) &&
                <FrequentlyBoughtTogether productid={productState.product.id} style={{ marginBottom: 50 }} />
                /* <FromTheManufacturer style={{marginBottom: 50}}/> */
            }


            {/* Start: Social Share Plugin Modal  */}
            <Modal
                show={shareModal}
                centered
                onHide={() => closeShareModal()}
                animation={false}
                backdrop="static"
                keyboard={false}
                className="customizationPopup"
                size="sm"

            >
                <Modal.Body>
                    <div className="closeButtonContainer floatButton">
                        <CloseButton onClick={() => closeShareModal()} />
                    </div>
                    <div className="customHeadingForForm">
                        <p className="mb-3 fs-5 text-center pageHeading">
                            Share this on social media

                        </p>
                        <InlineShareButtons config={{
                            alignment: 'center',  // alignment of buttons (left, center, right)
                            color: 'social',      // set the color of buttons (social, white)
                            enabled: true,        // show/hide buttons (true, false)
                            font_size: 12,        // font size for the buttons
                            labels: null,        // button labels (cta, counts, null)
                            language: 'en',       // which language to use (see LANGUAGES)
                            networks: [           // which networks to include (see SHARING NETWORKS)

                                'linkedin',
                                'facebook',
                                'twitter',
                                'whatsapp',
                                'email',
                            ],
                            padding: 12,          // padding within buttons (INTEGER)
                            radius: 50,            // the corner radius on each button (INTEGER)
                            show_total: false,
                            size: 45,             // the size of each button (INTEGER)

                            // OPTIONAL PARAMETERS

                        }} />
                        {/* <InlineReactionButtons /> */}
                    </div>
                    <Col>
                        <div className="sharethis-inline-share-buttons"></div>
                    </Col>
                </Modal.Body>
            </Modal>

            {/* Ends: Social Shar5e Plugin Modal  */}

        </>
    )
}

export default ProductDetails