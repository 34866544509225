import React, { useState, useEffect, useReducer, useRef } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "../LatestProductsSlider/LatestProductsSlider.scss";
import { Link } from "react-router-dom";
import { productReducer, productsInitialState } from "../../reducers/Reducers";
import ProductItem from "../ProductItem/ProductItem";
import SwiperCore, { Navigation, Scrollbar, Autoplay } from "swiper";
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { productSliderParams } from "../../helpers/DOMHelpers";
import { getC3ProductsAPI } from "../../apis/generalAPIs";

SwiperCore.use([Navigation, Autoplay, Scrollbar]);
const C3SupplierProducts = (props) => {
  const [isFirstSlide, setIsFirstSlide] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const swiperRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [productState, productDispatch] = useReducer(
    productReducer,
    productsInitialState
  );
  const loadContent = async () => {
    const apiResult = await getC3ProductsAPI();
    if (apiResult.status) {
      productDispatch({
        type: "FETCH_PRODUCTS_SUCCESS",
        payload: apiResult.data.items,
      });
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <>
      {productState.products && productState.products.length > 0 && (
        <Container className="productSlider latestProductsSlider sliderBottomSpacing">
          <Row>
            <Col
              className="productSliderHeadingContainer  promotion"
              xl={2}
              lg={3}
              md={3}
              sm={4}
              xs={12}
            >

              <div className="heading">Handmade Products by C3 Sisters</div>


              <Link
                to="/products?category=handmade-products&sort=newest-to-oldest&type=exclusive-products"
                className={`btn btn-whiteButton hasShadow mt-2 btn-sm promotionButton`}
              >
                view all
              </Link>


            </Col>
            <Col
              className={`productSliderContainer ${productState.hasError ? "hasError" : ""
                }`}
              xl={10}
              lg={9}
              md={9}
              sm={8}
              xs={12}
            >
              <>
                <div
                  onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
                  onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
                  className="sliderWrapper"
                >
                  <Swiper
                    ref={swiperRef}
                    navigation={{
                      prevEl: navigationPrevRef.current,
                      nextEl: navigationNextRef.current,
                    }}
                    {...productSliderParams}
                    onSwiper={(swiper) => {
                      setTimeout(() => {
                        swiper.params.navigation.prevEl =
                          navigationPrevRef.current;
                        swiper.params.navigation.nextEl =
                          navigationNextRef.current;
                        setIsFirstSlide(true);
                        swiper.navigation.destroy();
                        swiper.navigation.init();
                        swiper.navigation.update();
                      });
                    }}
                    onSlideChange={(swiper) => {
                      if (swiper.activeIndex === 0) {
                        setIsFirstSlide(true);
                      } else if (swiper.isEnd) {
                        setIsLastSlide(true);
                      } else {
                        setIsFirstSlide(false);
                        setIsLastSlide(false);
                      }
                    }}
                  >
                    {productState.products.map((item, index) => (
                      <SwiperSlide
                        className={`swiper-slide productSlideContent ${!item.in_stock ? "outOfStock" : ""}`}
                        key={index}
                      >
                        <ProductItem key={item.id.toString()} item={item} promotion="yes" />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div
                    ref={navigationPrevRef}
                    className={`customSliderNavigation promotion prev ${isFirstSlide ? "hide" : ""
                      }`}
                  >
                    <FaCaretRight className="customSliderPrevIcon" />
                  </div>
                  <div
                    ref={navigationNextRef}
                    className={`customSliderNavigation promotion  next ${isLastSlide ? "hide" : ""
                      }`}
                  >
                    <FaCaretLeft className="customSliderNextIcon" />
                  </div>
                </div>
              </>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default C3SupplierProducts;
