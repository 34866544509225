import React, { useState, useContext, useReducer, useEffect } from 'react'
import ExistingShippingAddress from './ExistingShippingAddress'
import AddShippingAddress from './AddShippingAddress'
import { Button } from 'react-bootstrap'
import { GlobalContext } from './../../contexts/GlobalContext';
import { ShippingAddressProvider } from '../../contexts/ShippingAddressContext'
import { AuthContext } from '../../contexts/AuthContext'


const ShippingAddress = () => {
    const { userAuthState } = useContext(AuthContext);
    // const [itemState, itemDispatch] = useReducer(itemReducer, itemInitialState)
    const [showShippingAddressForm, setShippingAddressForm] = useState(false)
    const toggleShippingAddress = () => {
        setShippingAddressForm(showShippingAddressForm => !showShippingAddressForm);
    }


    // const getData = async () => {
    //     await axios.get(`${process.env.REACT_APP_BASE_URL}api/buyers-shipping-address`,
    //         {
    //             headers: {
    //                 Authorization: `Bearer ${userAuthState.user && userAuthState.user.token ? userAuthState.user.token : (null)}`,
    //             },
    //         }
    //     )
    //         .then(res => {
    //             itemDispatch({
    //                 type: "FETCH_SUCCESS",
    //                 payload: res.data
    //             });
    //             console.log(res.data);
    //         })
    //         .catch(error => {
    //             itemDispatch({
    //                 type: "FETCH_FAILURE"
    //             });
    //         });
    // }

    // Frontend Messages - Shipping Address //
    const { getFrontendMessage } = useContext(GlobalContext);
    const shippingAddressHeading = getFrontendMessage('Checkout', 'Shipping Address', 'Heading', 'Text')
    const addAddressButtonText = getFrontendMessage('Checkout', 'Shipping Address', 'Button Text', 'Text')

    return (
        <ShippingAddressProvider>
            <div className="shippingDetails">
                <div className="pageHeading mb-4">{shippingAddressHeading}</div>
                {userAuthState.user && userAuthState.user.token &&
                    <ExistingShippingAddress />
                }
                {showShippingAddressForm === false &&
                    <Button className="hasShadow hasPadding uppercase" variant="whiteButton" size=""
                        type="button"
                        onClick={() => toggleShippingAddress()}
                    >
                        {addAddressButtonText}
                    </Button>
                }
                {showShippingAddressForm &&
                    <div className="mt-4">
                        <AddShippingAddress toggleShippingAddress={toggleShippingAddress} />
                    </div>
                }
            </div>
        </ShippingAddressProvider>

    )
}

export default ShippingAddress
