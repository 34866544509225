import React, { useContext } from 'react'
import OrderByWhatsApp from './OrderByWhatsApp'
import Login from '../../pages/Login/Login'
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import whatsAppIcon from '../../assets/images/whatsAppIcon.svg'
import GuestSignup from '../../pages/Signup/GuestSignup'
import { GlobalContext } from './../../contexts/GlobalContext';
import { AuthContext } from "../../contexts/AuthContext"

// import UserDetailsAfterSignin from '../../components/UserDetailsAfterSignin/UserDetailsAfterSignin'
const PlaceOrderItem = ({ setOrderPlacedByWhatsApp, setOrderNumber }) => {
    // Frontend Messages - Shopping Cart //
    const { getFrontendMessage } = useContext(GlobalContext);
    const orderByWhatsAppTabText = getFrontendMessage('Cart', 'Order by WhatsApp', 'Tab Text', 'Text')
    const guestCheckoutTabText = getFrontendMessage('Cart', 'Guest Checkout', 'Tab Text', 'Text')
    const orderByWhatsAppFormButtonText = getFrontendMessage('Forms', 'Order by WhatsApp', 'Button Text', 'Text')
    const orderByWhatsAppFormHeading = getFrontendMessage('Forms', 'Order by WhatsApp', 'Form Heading', 'Text')

    const guestCheckoutFormButtonText = getFrontendMessage('Forms', 'Guest Checkout', 'Button Text', 'Text')
    const guestCheckoutFormHeading = getFrontendMessage('Forms', 'Guest Checkout', 'Form Heading', 'Text')
    const guestCheckoutFormSuccessMessage = getFrontendMessage('Forms', 'Guest Checkout', 'Success Message', 'Paragraphs')
    const { userAuthState } = useContext(AuthContext);
    return (
        <div className="placeOrderitem customTab horizontal">

            <>
                {userAuthState.user && userAuthState.user.token ? (
                    null
                ) : (
                    <Tab.Container id="left-tabs-example" defaultActiveKey="guestLogin" transition={false}>
                        <Row>
                            <Col sm={4} md={3}>
                                <Nav variant="pills" className="flex-column">

                                    {/* <>
                                        <Nav.Item>
                                            <Nav.Link eventKey="orderyWhatsApp" className="uppercase" title="Order via WhatsApp">
                                                <img src={whatsAppIcon} alt="" className="whatsupIcon" />
                                                <br />
                                                {orderByWhatsAppTabText}
                                            </Nav.Link>
                                        </Nav.Item>

                                        <div className="dividor">
                                            -- or --
                                        </div>
                                    </> */}

                                    <Nav.Item>
                                        <Nav.Link eventKey="guestLogin" className="hasExtraPaddingVerticle">
                                            {guestCheckoutTabText}
                                        </Nav.Link>
                                    </Nav.Item>

                                    <>
                                        <div className="dividor">
                                            -- or --
                                        </div>
                                        <Nav.Item>
                                            <Nav.Link eventKey="signin_signup" className="hasExtraPaddingVerticle">
                                                Sign In / Sign Up
                                            </Nav.Link>
                                        </Nav.Item>
                                    </>

                                </Nav>

                            </Col>

                            <Col sm={8} md={9} >
                                <Tab.Content>

                                    {/* <Tab.Pane eventKey="orderyWhatsApp">
                                        <OrderByWhatsApp
                                            setOrderPlacedByWhatsApp={setOrderPlacedByWhatsApp}
                                            setOrderNumber={setOrderNumber}
                                            orderByWhatsAppFormHeading={orderByWhatsAppFormHeading}
                                            orderByWhatsAppFormButtonText={orderByWhatsAppFormButtonText}

                                        />
                                    </Tab.Pane> */}


                                    <Tab.Pane eventKey="signin_signup">
                                        <Login />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="guestLogin">
                                        <GuestSignup
                                            guestCheckoutFormHeading={guestCheckoutFormHeading}
                                            guestCheckoutFormSuccessMessage={guestCheckoutFormSuccessMessage}
                                            guestCheckoutFormButtonText={guestCheckoutFormButtonText}
                                        />
                                    </Tab.Pane>

                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                )}
            </>

        </div>
    )
}

export default PlaceOrderItem
