import React, { useReducer, useState, useEffect, useContext } from 'react'
import { Form, Button, Col,Alert } from 'react-bootstrap'
import { Formik } from 'formik'
import FormInput from '../../components/FormPartials/FormInput'
import CountriesInput from '../../components/FormPartials/CountriesInput'
import GenderInput from '../../components/FormPartials/GenderInput'
import { formSubmissionReducer, formInitialState } from '../../reducers/Reducers'
import { ContainerLoading } from "../../components/Loading/Loading"
import SuccessMsg from '../../components/FormPartials/SuccessMsg'
import { ErrorComponent, FormSubmissionFailed } from '../../components/Alert/Alert'
import ToastFormSubmission from '../../components/Toasts/ToastFormSubmission'
import { toast } from "react-toastify";
import * as DOMHelpers from "../../helpers/DOMHelpers"
import { guestSignUpValidation } from '../../components/FormPartials/Validation'
import AllowToMakeProfile from '../../components/AllowToMakeProfile/AllowToMakeProfile'
import MendatoryFieldMsg from '../../components/FormPartials/MendatoryFieldMsg'
import { AuthContext } from "../../contexts/AuthContext"
import TelephoneInput from '../../components/FormPartials/TelephoneInput'
import { useHistory } from 'react-router-dom';
import { getGuestSessionToken } from '../../helpers/CommonHelper';

import { checkEmailExist, registerGuest } from '../../apis/buyerAPIs'
import { BackdropContext } from '../../contexts/BackdropContext'
import { OverlayContext } from '../../contexts/OverlayContext'
import { PopupContext } from '../../contexts/PopupContext'

const GuestSignup = ({
    guestCheckoutFormHeading,
    guestCheckoutFormSuccessMessage,
    guestCheckoutFormButtonText,
    ...props }) => {
    let history = useHistory();
    const [formState, formDispatch] = useReducer(formSubmissionReducer, formInitialState);
    const { userAuthDispatch } = useContext(AuthContext);
    const { backdropState, backdropDispatch } = useContext(BackdropContext);
    const { userOverlayState, userOverlayDispatch } = useContext(OverlayContext);
    const { popupDispatch } = useContext(PopupContext);
    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
       // country: "",
        gender: "",
        create_my_profile: "",
        type: "Express",
        guest_cart_session_token: getGuestSessionToken()
    };

    const [emailExistStatus, setEmailExistStatus] = useState(false)
    return (
        <div>

            {/* <div>
                {(itemState && itemState.item.user) &&
                    <>
                        <>
                            Name: {itemState.item.user.first_name}
                            Token: {itemState.item.user.token}
                        </>

                    </>
                }
            </div> */}



            <div className="formContainer">
                {formState ? (
                    <>
                        {formState.isFormSubmitting && <ContainerLoading />}
                        {formState.isFormSubmitted ? (
                            <SuccessMsg
                                msgTitle={guestCheckoutFormSuccessMessage && guestCheckoutFormSuccessMessage.length ? guestCheckoutFormSuccessMessage[0].text : ''}
                                msgBody={guestCheckoutFormSuccessMessage && guestCheckoutFormSuccessMessage.length ? guestCheckoutFormSuccessMessage[1].text : ''}
                            />
                        ) : (
                            <>
                                <div className="customHeadingForForm">
                                    <p>
                                        {guestCheckoutFormHeading}
                                    </p>
                                </div>
                                {formState.hasSubmissionError && <FormSubmissionFailed />}
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    validationSchema={guestSignUpValidation}
                                    validateOnChange={true}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { setSubmitting, resetForm }) => {

                                        // if (emailExistStatus)
                                        //     return false;

                                        setSubmitting(true);
                                        formDispatch({
                                            type: "FORM_SUBMISSION_START",
                                        });

                                        const submissionResult = await registerGuest(values);
                                        //console.log(submissionResult);
                                        if (submissionResult.status) {

                                            userAuthDispatch({
                                                type: "LOGIN",
                                                payload: submissionResult
                                            })

                                            formDispatch({
                                                type: 'FORM_SUBMISSION_SUCCESS',
                                            });

                                            setTimeout(() => {
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                                history.push("/checkout")
                                            }, 5000);
                                        } else {
                                            console.log('failed dispatch');
                                            formDispatch({
                                                type: "FORM_SUBMISSION_FAILED",
                                            });
                                            setTimeout(() => {
                                                formDispatch({
                                                    type: "FORM_INITIAL_STAGE",
                                                });
                                            }, 5000);
                                        }
                                    }}
                                >
                                    {({ values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting }) => (
                                        <Form onSubmit={handleSubmit} className="mx-auto">
                                            <input type="hidden" name="type" value={values.type} />
                                            <Col className={`p-0 mendatory ${errors.first_name && touched.first_name ? "errorParent" : ""}`}>
                                                <FormInput
                                                    controlId="first_name"
                                                    label="Your First Name"
                                                    name="first_name"
                                                    type="text"
                                                    value={values.first_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Col>
                                            <Col className={`p-0 mendatory ${errors.last_name && touched.last_name ? "errorParent" : ""}`}>
                                                <FormInput
                                                    controlId="last_name"
                                                    label="Your Last Name"
                                                    name="last_name"
                                                    type="text"
                                                    value={values.last_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Col>
                                            <Col className={`p-0 mendatory ${errors.email && touched.email ? "errorParent" : ""}`}>
                                                <FormInput
                                                    controlId="email"
                                                    label="Your Email Address"
                                                    name="email"
                                                    type="email"
                                                    value={values.email}
                                                    onChange={(e) => { handleChange(e); emailExistStatus === true && setEmailExistStatus(false) }}
                                                    onBlur={async (e) => {
                                                        handleBlur(e);
                                                        const result = await checkEmailExist(values.email);
                                                        setEmailExistStatus(result)
                                                    }
                                                    }
                                                />
                                                {(!errors.email && emailExistStatus) &&
                                                    <Alert variant="warning">
                                                        We have detected an account associated with the email ID you provided. <br />
                                                        We recommend you to  <a onClick={() => {
                                                            popupDispatch({
                                                                type: "POPUP_SIGNIN_OPEN",
                                                            });
                                                            userOverlayDispatch({
                                                                type: "OVERLAY_CLOSE",
                                                            });
                                                            backdropDispatch({
                                                                type: "FETCH_BACKDROP_CLOSE",
                                                            });
                                                        }} className="primaryPink">login</a> to your account for better shopping experience or you can click the button below to continue as guest.

                                                    </Alert>
                                                }
                                            </Col>

                                            <Form.Row>
                                                <Col
                                                    xl={6} lg={12} md={12} sm={12} xs={12}
                                                    className={`mendatory ${errors.mobile && touched.mobile ? "errorParent" : ""}`}>
                                                    <TelephoneInput
                                                        label="My Mobile Number"
                                                        name="mobile"
                                                    />
                                                </Col>
                                                <Col xl={6} lg={12} md={12} sm={12} xs={12}
                                                    className={`mendatory ${errors.gender && touched.gender ? "errorParent" : ""}`}
                                                >
                                                    <GenderInput
                                                        controlId="gender"
                                                        label="Select Your Gender"
                                                        name="gender"
                                                        as="select"
                                                        value={values.gender}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Col>
                                            </Form.Row>

                                            {/* <Col className={`p-0 mendatory ${errors.country && touched.country ? "errorParent" : ""}`}>
                                                <CountriesInput
                                                    listType='all'
                                                    controlId="country"
                                                    label="Select Country of Residence"
                                                    name="country"
                                                    as="select"
                                                    value={values.country}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                            </Col> */}
                                            {(!emailExistStatus) &&
                                            <AllowToMakeProfile
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                values={values}
                                            />}
                                            <Col className="p-0 buttonContainer">
                                                <Button variant="PurpleButton"
                                                    className="hasPadding uppercase hasShadow bold"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {guestCheckoutFormButtonText}
                                                </Button>
                                            </Col>

                                            <Col className="p-0 mt-3 textRight">
                                                <MendatoryFieldMsg />
                                            </Col>

                                        </Form>
                                    )}
                                </Formik>
                            </>

                        )}
                    </>
                ) : (
                    <ErrorComponent />
                )}
            </div>
        </div>
    )
}

export default GuestSignup
