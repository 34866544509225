import React, { useState, useContext } from "react"
import { Link } from "react-router-dom"
import AddToCart from "../Cart/AddToCart/AddToCart"
import AddToWishlist from "../AddToWishlist/AddToWishlist"
import Rating from 'react-rating'
import { Badge } from 'react-bootstrap'
import { isTrue } from "../../helpers/DOMHelpers"
import QuickView from "./QuickView"
import { CartContext } from "../../contexts/CartContext"
import { isItemInCart } from "../../helpers/DOMHelpers"
import AddedToCart from "../Cart/AddedToCart/AddedToCart"
import { GlobalContext } from './../../contexts/GlobalContext';
import { IoBanOutline } from "react-icons/io5";
import Price from './Price';
import { Modal } from 'react-bootstrap'
import NotifyMe from '../../components/OutOfStock/NotifyMe';


import ratingStar from '../../assets/images/rating_star.png'
import ratedStar from '../../assets/images/rated_star.png'


export const ProductsItem = ({ item, productid, promotion }) => {
    const [openPopup, setOpenPopup] = useState(false)
    const { defaultSelectedCurrency } = useContext(GlobalContext).globalData;
    const { currencies } = useContext(GlobalContext).globalData.data;
    const [isCartButtonStyle, setIsCartButtonStyle] = useState(false)
    const { cart } = useContext(CartContext);

    const filteredSpecficationsForQuickView = () => {

        let filteredProductSpecs = [];
        let filteredSubCatSpecs = [];
        if (
            item.specifications
            && (
                (item.specifications.product && item.specifications.product.length > 0)
                || (item.specifications.sub_category && item.specifications.sub_category.length > 0)
            )
        ) {

            if (item.specifications.product && item.specifications.product.length > 0) {
                filteredProductSpecs = item.specifications.product.filter(spec => spec.show_in_quick_view === 1)
            }

            if (item.specifications.sub_category && item.specifications.sub_category.length > 0) {
                filteredSubCatSpecs = item.specifications.sub_category.filter(spec => spec.show_in_quick_view === 1)
            }


        }
        const filteredAllSpecs = filteredProductSpecs.concat(filteredSubCatSpecs);
        return (filteredAllSpecs.length > 0) ? filteredAllSpecs.slice(0, 5) : [];
    }

    return (
        <>
            {(item) && <div className={`itemHolder ${promotion == "yes" ? "promotion" : ""} `}>
                {(filteredSpecficationsForQuickView().length > 0) &&
                    < QuickView
                        specifications={filteredSpecficationsForQuickView()}
                        itemImage={item.image}
                        itemTitle={item.title}
                    />
                }
                <div className="itemImageHolder">
                    {(item.old_price && item.old_price > item.price) && (
                        <div className="offerTag starburst sm">
                            <div className="text">
                                {Math.round(((item.old_price - item.price) / item.old_price) * 100)}%
                                <br />
                                <span className="uppercase">OFF</span>
                            </div>
                        </div>
                    )}

                    {isTrue(item.is_customizable) &&
                        <Badge pill variant="secondaryPink" className="isCustomizableBadge">
                            Customizable
                        </Badge>
                    }



                    <Link to={`/product/${item.slug}`}>
                        <span className="itemImageWrapper">
                            <img
                                className="itemImage"
                                src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                alt={item.title}
                                title={item.title}
                            />
                            {!item.in_stock && <>  <Badge pill variant="tertiaryGray" className="outofStockBadge">
                                Out of Stock
                            </Badge></>}
                        </span>

                    </Link>
                </div>

                <div className="itemDetails">
                    <ul>
                        {/* <li>item ID: {item.id}</li> */}
                        {item.last_piece_message && <li className="oneleftText">
                            {item.last_piece_message}
                        </li>}

                        <li className="itemCategory">
                            <Link to={`/product/${item.slug}`}>
                                {item.category.name === "" ? (
                                    <p>{item.type.name}</p>
                                ) : (
                                    <>
                                        {item.subCategory.name === "" ? (
                                            <p>{item.category.name}</p>
                                        ) : (
                                            <p>{item.subCategory.name}</p>
                                        )}
                                    </>
                                )}
                                <>
                                </>
                            </Link>
                        </li>
                        <li className="itemTitle">
                            <Link to={`/product/${item.slug}`}>
                                {item.title}
                            </Link>
                        </li>


                        <li className="itemRating productRating sm mt-1">

                            <Rating
                                emptySymbol={<img src={ratingStar} className="icon" alt="" />}
                                fullSymbol={<img src={ratedStar} className="icon" alt="" />}
                                readonly={true}
                                initialRating={item.reviews.rating}
                            />
                        </li>

                        <li>
                            <div className="productItemActionButtons" >
                                <ul>

                                    <li >

                                        <Price price={item.price} oldPrice={item.old_price} promotion="c3" />



                                    </li>
                                    <li>
                                        <div className="addToWishlist textCenter" >
                                            <AddToWishlist
                                                product_id={item.id}
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        {item.in_stock ?
                                            <div className="addToCart textCenter" >
                                                {cart && cart.cartItems &&
                                                    isItemInCart(item.id, cart.cartItems.items)
                                                    ? <AddedToCart
                                                        isCartButtonStyle={isCartButtonStyle}
                                                    />
                                                    : <AddToCart item={item}
                                                        isCartButtonStyle={isCartButtonStyle}
                                                    />
                                                }
                                            </div> : <><IoBanOutline title="Out of stock "
                                                onClick={() => setOpenPopup(true)}

                                                className="icon textCenter" style={{ width: '1.65rem', height: '1.65rem', cursor: 'pointer', marginTop: '0.25rem' }}
                                            /></>}
                                        <Modal
                                            size="md"
                                            show={openPopup}
                                            centered
                                            onHide={() =>
                                                setOpenPopup(false)
                                            }
                                            animation={false}
                                            className=""
                                        >
                                            <Modal.Body>
                                                <div className="formContainer">
                                                    <NotifyMe
                                                        setOpenPopup={setOpenPopup}
                                                        itemID={item.id}
                                                    />
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>}

        </>
    );
};

export default ProductsItem;
